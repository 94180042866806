import { Message, Spacer } from 'vcc-ui';

type ModalButton = {
  text: string;
  dataColor?: string;
  onClick: () => void;
  disabled?: boolean;
};

type ModalButtonsProps = {
  closeButton: ModalButton;
  acceptButton?: ModalButton;
  isLoading?: boolean;
  errorMessage?: string;
};

const ModalButtons: React.FC<ModalButtonsProps> = ({
  closeButton,
  acceptButton,
  isLoading,
  errorMessage,
}) => (
  <>
    <Spacer size={errorMessage ? 3 : 5} />
    {errorMessage && (
      <>
        <Message type="error">{errorMessage}</Message>
        <Spacer />
      </>
    )}

    <div className="button-group justify-center">
      {acceptButton && (
        <button
          type="button"
          data-color={acceptButton.dataColor}
          className="button-filled"
          disabled={isLoading || acceptButton.disabled}
          onClick={acceptButton.onClick}
        >
          {acceptButton.text}
        </button>
      )}
      <button
        type="button"
        className="button-outlined"
        onClick={closeButton.onClick}
      >
        {closeButton.text}
      </button>
    </div>
  </>
);

export default ModalButtons;
